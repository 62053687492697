import * as React from 'react';
import { useRef } from 'react';
import DesignServicesForm from 'bundles/App/pages/service/DesignServices/DesignServicesForm';
import Footer from 'styleguide/components/Footer/Footer';
import InformationWithSteps from 'styleguide/components/InformationWithSteps/InformationWithSteps';
import AdditionalInfo from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/AdditionalInfo';
import FAQ from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/FAQ/FAQ';
import Meta from 'styleguide/components/Meta/Meta';
import {
  designRequestFaqs,
  designServiceSteps,
  designServicesWhyPrintivity,
} from 'bundles/App/pages/service/DesignServices/designServicesData';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import formImg from 'assets/images/DesignServices/room-to-root.png';
import Reviews from 'styleguide/components/Reviews/Reviews';
import Hero from './Hero/Hero';
import OurLatestWork from './OurLatestWork/OurLatestWork';
import PricingSection from './PricingSection/PricingSection';

const DesignServicesFormSection = ({ inputRef }: { inputRef: React.Ref<HTMLInputElement> }) => (
  <div className="mb-16 mt-24 flex" id="design-services-form">
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 53.74%, rgba(0, 0, 0, 0.77) 100%), url('${formImg}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
      }}
      className="hidden w-1/2 lg:block"
    />
    <div className="flex w-full justify-center lg:w-1/2">
      <DesignServicesForm ref={inputRef} />
    </div>
  </div>
);

const DesignServices = () => {
  const inputRef: React.MutableRefObject<HTMLInputElement> = useRef(null);
  const setFocus = () => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1000);
  };

  return (
    <>
      <Meta
        title="Best Graphic Design & Printing Services Online | Printivity"
        canonical="https://www.printivity.com/design-services"
        description="Are you looking for a design & printing service company? Printivity is here for you. It's quick & easy; find a design, a product, & print. ✓ Start today!"
        faqs={designRequestFaqs}
        keywords="design printing services graphic design print printing company"
      />
      <Hero setFocus={setFocus} />
      <InformationWithSteps
        setFocus={setFocus}
        heading="How our design and print service works"
        steps={designServiceSteps}
        headingLevel="h2"
      />
      <OurLatestWork />
      <PricingSection setFocus={setFocus} />
      <DesignServicesFormSection inputRef={inputRef} />
      <Reviews />
      <div className="mx-auto mt-16 max-w-8xl xs:px-6 md:px-[100px] 3xl:px-0">
        <h4 className="heading-bold-sm">Why Printivity</h4>
        <HtmlContent className="!max-w-none !text-base" content={designServicesWhyPrintivity} />
      </div>
      <div className="mt-24">
        <AdditionalInfo faqHeading="Graphic Design FAQ" FAQ={<FAQ questions={designRequestFaqs} />} />
      </div>
      <Footer />
    </>
  );
};

export default DesignServices;
