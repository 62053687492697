import * as React from 'react';
import { Carousel } from 'nuka-carousel';
import CustomArrows from './CarouselArrows';

interface Props {
  children: React.ReactNode;
  scrollDistance?: number | 'slide' | 'screen';
  showDots?: boolean;
  showArrows?: boolean;
  swiping?: boolean;
}

const NCarousel = ({
  children,
  scrollDistance = 'slide',
  showDots = false,
  showArrows = false,
  swiping = false,
}: Props) => (
  <div className="relative mx-auto max-w-7xl pl-6">
    <Carousel
      className="mx-auto"
      wrapMode="wrap"
      showDots={showDots}
      showArrows={showArrows}
      arrows={<CustomArrows />}
      scrollDistance={scrollDistance}
      swiping={swiping}
    >
      {children}
    </Carousel>
  </div>
);

export default NCarousel;
