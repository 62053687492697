import * as React from 'react';
import Card from 'styleguide/components/Card/Card';
import IconClock from 'styleguide/icons/IconClock';
import { pricingCards } from 'bundles/App/pages/service/DesignServices/designServicesData';
import A from 'styleguide/components/Links/A';
import DesignServicesCarousel from 'styleguide/components/Carousel/Carousel';

const PricingSection = ({ setFocus }) => (
  <div className="relative mx-auto max-w-8xl pb-36 text-center xl:pb-48">
    <h4 className="heading-heavy-sm mx-auto mb-8">Pricing</h4>
    <DesignServicesCarousel scrollDistance={296} showArrows>
      {pricingCards.map((card, i: number) => (
        <div className="mr-2 min-w-72" key={i}>
          <Card
            className="mb-1"
            imageClassName="!object-cover"
            image={{ url: card.image.url, alt: card.image.alt, width: 277, height: 306 }}
            title={card.title}
            titleClassName="text-start"
            key={`${card.image.alt}_${i}`}
            linkText="Get quote"
            onClick={setFocus}
            Bubble={
              <div className="flex flex-col items-end justify-center p-3">
                {card.bubble?.price && (
                  <div className="paragraph-bold-desktop text-white">From {card.bubble.price}</div>
                )}
                {card.bubble?.unit && (
                  <div className="caption text-right text-white">per {card.bubble.unit}</div>
                )}
                {card.bubble?.linkText && (
                  <A
                    className="!font-hvMedium !text-sm !text-white"
                    underline="none"
                    href={card.bubble?.linkUrl}
                    color="black"
                  >
                    {card.bubble?.linkText}
                  </A>
                )}
              </div>
            }
            Body={
              <div className="px-4 text-start">
                <div className="paragraph-mobile flex items-center">
                  <IconClock className="mr-1" size="xs" color="dark" />
                  {card.turnaround}
                </div>
                {card.info.map((info, index: number) => (
                  <div className="paragraph-mobile mt-2" key={index}>
                    {info}
                  </div>
                ))}
              </div>
            }
          />
        </div>
      ))}
    </DesignServicesCarousel>
  </div>
);

export default PricingSection;
