import * as React from 'react';
import cn from 'classnames';
import { ForwardedRef, forwardRef, useState } from 'react';
import { object, string, number, array } from 'yup';
import { useHistory } from 'react-router-dom';
import { FieldArray, Form, Formik, FormikErrors, FormikValues } from 'formik';
import { emptyDesignServiceRequestForm } from 'bundles/App/pages/ContactUsPage/types';
import { phoneRegex } from 'utils/schema';
import { submitForm } from 'api/contactUs';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import { products, serviceTypes } from 'bundles/App/pages/ContactUsPage/formOptions';
import { FormikFieldWrapper } from 'styleguide/components/Formik';
import Captcha from 'bundles/App/pages/ContactUsPage/Captcha';
import Button from 'styleguide/components/Button/Button';
import { Status } from 'libs/utils/api/types';
import TwoColumnFormField from 'styleguide/components/forms/Field/TwoColumnFormField';
import UploadFilesModal from 'styleguide/components/ProductsTable/UploadFilesModal/UploadFilesModal';
import { GrAttachment } from '@react-icons/all-files/gr/GrAttachment';
import { Upload } from 'api/orders/types';
import { contactUsPath } from 'bundles/App/routes';

const designServiceRequestSchema = object().shape({
  userInput: object().shape({
    name: string().required('Name is required'),
    email: string().email('Please enter a valid email.').required('Email is required'),
    phoneNumber: string()
      .required('Phone number is required')
      .matches(phoneRegex, 'Phone number is not valid'),
  }),
  designServiceRequest: object().shape({
    serviceType: string().required('Service type is required'),
    projectName: string().required('Project name is required'),
    deadline: string(),
    product: string().required('Product is required'),
    numPages: number().nullable().typeError('Pages must be a number'),
    width: number().nullable().typeError('Width must be written as a number'),
    height: number().nullable().typeError('Height must be written as a number'),
    description: string(),
    attachments: array().of(
      object<Upload>().shape({
        id: number(),
        name: string(),
        url: string(),
      }),
    ),
  }),
});

const DesignServiceForm = forwardRef((props, ref: ForwardedRef<HTMLInputElement>) => {
  const [recaptchaResponse, setRecaptchaResponse] = useState<string>(null);
  const [showUploadModal, setShowUploadModal] = React.useState(false);

  const history = useHistory();

  const onSubmit = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    submitForm({
      ...values,
      recaptchaResponse,
      formType: 'designServiceRequest',
    }).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        history.push(`${contactUsPath}/success`);
      }
    });
  };
  return (
    <Formik
      initialValues={emptyDesignServiceRequestForm}
      onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
        onSubmit(values, setStatus, setSubmitting, setErrors);
      }}
      validationSchema={designServiceRequestSchema}
    >
      {formikProps => (
        <Form className="w-full px-6 sm:w-[500px] 4xl:w-2/3" data-cy="designServicesForm">
          <div className="heading-bold-sm mb-8 text-center lg:text-left">
            Have a project? <br /> We would love to help.
          </div>
          <FormikFieldWrapper
            name="designServiceRequest.serviceType"
            inPlaceError
            setRef={ref}
            id="type-of-service"
            data-cy="designServiceRequest.serviceType"
            componentType="combobox"
            labelComponent="What type of service are you looking for?"
            className="scroll-mt-48"
            options={serviceTypes}
            onChange={e => {
              if (e.key === 'I have a complete file and would like someone to check if it’s print-ready.') {
                history.push(`${contactUsPath}?subject='file-check-request'`);
              }
            }}
          />
          <FormikFieldWrapper
            name="userInput.name"
            inPlaceError
            id="full-name"
            data-cy="userInput.name"
            componentType="input"
            labelComponent="Your Name"
            inputClassName="mb-1"
          />
          <TwoColumnFormField leftFieldClassName="!pl-0 -md:!pr-0" rightFieldClassName="!pr-0 -md:!pl-0">
            <FormikFieldWrapper
              name="userInput.email"
              type="email"
              inPlaceError
              componentType="input"
              labelComponent="Your Email"
              inputClassName="mb-1"
              data-cy="userInput.email"
            />
            <FormikFieldWrapper
              name="userInput.phoneNumber"
              inPlaceError
              componentType="input"
              labelComponent="Phone Number"
              inputClassName="mb-1"
              data-cy="userInput.phoneNumber"
            />
          </TwoColumnFormField>
          <FormikFieldWrapper
            name="designServiceRequest.projectName"
            inPlaceError
            componentType="input"
            labelComponent="Project Name"
            inputClassName="mb-1"
            data-cy="designServiceRequest.projectName"
          />
          <FormikFieldWrapper
            name="designServiceRequest.product"
            componentType="combobox"
            inPlaceError
            optionGroups={products}
            creatable
            labelComponent="Product Type"
            data-cy="designServiceRequest.product"
          />
          <FormikFieldWrapper
            name="designServiceRequest.numPages"
            inPlaceError
            componentType="input"
            labelComponent="How Many Pages?"
            inputClassName="mb-1"
            data-cy="designServiceRequest.numPages"
          />
          <TwoColumnFormField leftFieldClassName="!pl-0 -md:!pr-0" rightFieldClassName="!pr-0 -md:!pl-0">
            <FormikFieldWrapper
              name="designServiceRequest.width"
              inPlaceError
              componentType="input"
              labelComponent="Width"
              data-cy="designServiceRequest.width"
            />
            <FormikFieldWrapper
              name="designServiceRequest.height"
              inPlaceError
              componentType="input"
              labelComponent="Height"
              data-cy="designServiceRequest.height"
            />
          </TwoColumnFormField>
          <FormikFieldWrapper
            name="designServiceRequest.deadline"
            inPlaceError
            componentType="input"
            labelComponent="How Soon Do You Need Your Design?"
            data-cy="designServiceRequest.deadline"
          />
          <FormikFieldWrapper
            name="designServiceRequest.description"
            rows={5}
            componentType="textarea"
            labelComponent="Tell Us About Your Project"
            data-cy="designServiceRequest.description"
          />
          <div className="mt-6">
            <div
              className="text-sm font-hvMedium flex items-center cursor-pointer"
              onClick={() => setShowUploadModal(true)}
              tabIndex={0}
              role="button"
              data-cy="attachmentsButton"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  setShowUploadModal(true);
                }
              }}
            >
              <GrAttachment className="mr-2" />
              Add attachment
            </div>
            <FieldArray
              name="designServiceRequest.attachments"
              render={arrayHelpers => (
                <>
                  {(formikProps.values.designServiceRequest.attachments || []).map(attachment => (
                    <a
                      key={attachment.id}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={attachment.url}
                      className={cn(
                        !!attachment.url && !!attachment === true
                          ? 'flex-grow break-all text-blue underline'
                          : 'flex-grow cursor-default break-all no-underline',
                      )}
                    >
                      <p
                        className={cn(
                          !!attachment.url && !!attachment === true && 'text-blue',
                          'm-0 flex-grow text-sm leading-5 md:leading-4 mt-2',
                        )}
                      >
                        {attachment.name}
                      </p>
                    </a>
                  ))}
                  {showUploadModal && (
                    <UploadFilesModal
                      onClose={() => setShowUploadModal(false)}
                      onAddUpload={(_dispatch, _owner, upload) => {
                        arrayHelpers.push(upload);
                      }}
                      onRemoveUpload={(_dispatch, id) => {
                        const index = formikProps.values.designServiceRequest.attachments.findIndex(
                          upload => upload.id === id,
                        );
                        arrayHelpers.remove(index);
                      }}
                      filesFromProps={formikProps.values.designServiceRequest.attachments}
                    />
                  )}
                </>
              )}
            />
          </div>
          <div className="sm:translate-0 -translate-x-8 scale-75 transform sm:scale-100 sm:transform-none">
            <Captcha verifyCallback={response => setRecaptchaResponse(response)} />
          </div>
          <div className="text-center lg:text-left">
            <Button color="blue" type="submit" size="md" className="!min-w-[250px]" dataCy="submitButton">
              Get Started
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default DesignServiceForm;
