import * as React from 'react';
import cn from 'classnames';
import { useCarousel } from 'nuka-carousel';
import { IconArrowLeft, IconArrowRight } from 'styleguide/icons';

function CustomArrows() {
  const { currentPage, totalPages, wrapMode, goBack, goForward } = useCarousel();

  const allowWrap = wrapMode === 'wrap';
  const enablePrevNavButton = currentPage > 0;
  const enableNextNavButton = allowWrap || currentPage < totalPages - 1;

  const slideCountClassName = cn('inline-block px-4 py-2 cursor-pointer');

  const nextNavClassName = cn(
    'inline-block px-4 py-2 cursor-pointer invisible',
    enableNextNavButton && '!visible',
  );

  return (
    <div className="flex justify-between">
      <div className={slideCountClassName}>
        <div className="paragraph-bold-desktop">
          {currentPage + 1}/{totalPages}
        </div>
      </div>
      <div className={nextNavClassName}>
        <div>
          {enablePrevNavButton && (
            <IconArrowLeft className="mr-6 cursor-pointer" onClick={goBack} size="xs" />
          )}
          {enableNextNavButton && <IconArrowRight className="cursor-pointer" onClick={goForward} size="xs" />}
        </div>
      </div>
    </div>
  );
}

export default CustomArrows;
